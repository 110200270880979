<template>
  <van-popover
    @select="select"
    v-model="showPopover"
    trigger="click"
    :actions="option">
    <template #reference>
                                <span class="popover-title" v-if="!useSlot">
                                    <span>{{value}}</span>
                                    <van-icon name="arrow-down" :class="showPopover && 'show'"/>
                                </span>
        <slot v-else></slot>
    </template>
  </van-popover>
</template>

<script>
  export default {
    name: "vSelect",
    props: {
      option: Array,
      title: String,
      type: String,
      defaultValue: String | Object,
      useSlot: Boolean
    },
    data() {
      return {
        showPopover: false,
        value: ''
      }
    },
    created() {
      this.value = this.title;
    },

    watch: {
      showPopover(){
        this.$emit('toggle',this.showPopover)
      },
      title(val) {
        if (val && val.length) {
          this.value = val;
        }
      },
      defaultValue: {
        handler(val) {
          this.refresh()
        },
        immediate: true
      },
      option: {
        handler(val) {
          this.refresh()
        },
        immediate: true
      },

    },

    methods: {
      select(data) {
        this.value = data.text;
        this.$emit('change', data, this.type);
      },


      refresh(){
        setTimeout(() => {
          const val = this.defaultValue
          const opt = this.option.find(o => o.value === val);
          if (opt) {
            this.value = opt.text;
          }
        });
      }
    }
  }
</script>

<style scoped lang="less">
  .popover-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebed;
    border-radius: 11px;
    padding: 0 32px 0 10px;
    height: 25px;
    position: relative;

    span {
      font-size: 12px;
    }

    i {
      position: absolute;
      right: 11px;
      top: 6px;
      transition: all .3s ease;
      font-size: 12px;

      &.show {
        transform: rotate(180deg);
      }
    }
  }
</style>
