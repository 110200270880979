<template>
  <model ref="model" class="club-dialog">
    <div class="title">支付成功</div>
    <div class="desc">码上加群主领取全部权益</div>
    <div class="desc">
      <img src="https://www.001ppt.com/redirect?id=lgyx_xzs" alt="">
    </div>
  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../components/model/model";
import {sleep} from "../../config/util";
import {mapGetters} from "vuex";

export default {
  components: {Model},
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    async show() {
      if (!this.userInfo || !Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG');
      }

      try {
        this.$loading('验证中');
        await sleep(1000)
        this.$refs.model.show();
      } finally {
        this.$loading.close();
      }
    },
    hide() {
      this.$refs.model.hide();
    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import './clubDialog.less';
</style>
