<template>
  <model no-padding ref="model" class="club-dialog" popper-class="club-pay-dialog">
    <img class="step-img" src="https://cdn.001ppt.cn/h5/img/club/step.png" alt="">
    <div class="confirm-btn" @click="startPay">马上入会</div>
  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../components/model/model";
import {sleep} from "../../config/util";
import {mapGetters} from "vuex";
import {clubV2Api} from "../../api/v2/clubV2Api";
import {consumeRecordV2Api} from "../../api/v2/consumeRecordV2Api";
import wxValidate from "../../config/wx.validate";

export default {
  components: {Model},
  mixins: [wxValidate],
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.wxSignature()
  },
  methods: {
    async show() {
      if (!this.userInfo || !Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG');
      }
      this.$refs.model.show();
    },
    hide() {
      this.$refs.model.hide();
    },

    // 发起支付
    async startPay() {
      try {
        this.$loading('请求中');
        await sleep(500)
        const res = await clubV2Api.startPayClub({payType: 1, paySubType: 11});
        wx.chooseWXPay({
          timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
          package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: res.paySign, // 支付签名
          success: async (r) => {
            // 支付成功后的回调函数
            this.hide();
            this.$emit('success')
          },
          fail: async (e) => {
            this.$toast.error(e.errMsg);
          },
        });
      } finally {
        this.$loading.close();
      }

    }
  }
}
</script>


------------------ styles ------------------
<style lang="less">
@import './clubDialog.less';
</style>
