<template>
  <model no-padding ref="model" class="club-dialog" popper-class="club-pay-dialog">
    <img class="step-img" src="https://cdn.001ppt.cn/h5/img/club/step.png" alt="">
    <div class="pay-btns">
      <div :class="`pay-btn wx ${payType===payTypeEnum.wx?'active':''}`" @click="payType=payTypeEnum.wx">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-green.svg" alt=""
             v-if="payType === payTypeEnum.wx">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-gray.svg" alt="" v-else>
        微信支付
      </div>
      <div :class="`pay-btn alipay ${payType===payTypeEnum.alipay?'active':''}`" @click="payType=payTypeEnum.alipay">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-blue.svg" alt=""
             v-if="payType === payTypeEnum.alipay">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-gray.svg" alt="" v-else>
        支付宝支付
      </div>
    </div>
    <div class="confirm-btn" @click="startPay">马上入会</div>
  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../components/model/model";
import {sleep} from "../../config/util";
import {mapGetters} from "vuex";
import {clubV2Api} from "../../api/v2/clubV2Api";
import {consumeRecordV2Api} from "../../api/v2/consumeRecordV2Api";
import wxValidate from "../../config/wx.validate";

export default {
  components: {Model},
  mixins: [wxValidate],
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.wxSignature()
  },

  data() {
    return {
      payType: 1,
      payTypeEnum: {
        wx: 1,
        alipay: 2
      }
    }
  },

  methods: {
    async show() {
      if (!this.userInfo || !Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG');
      }
      this.$refs.model.show();
    },
    hide() {
      this.$refs.model.hide();
    },

    // 发起支付
    async startPay() {
      try {
        this.$loading('请求中');
        await sleep(500)
        const payType = this.payType;
        const paySubType = payType === this.payTypeEnum.wx ? 13 : 21;
        const res = await clubV2Api.startPayClub({payType, paySubType});

        // 微信支付
        if (payType === this.payTypeEnum.wx) {
          window.location.replace(res.mwebUrl + '&redirect_url=' + encodeURIComponent(location.origin + '/result/wechat/' + res.bizNo + '?inner_redirect=' + location.href));
        }
        // 支付宝
        else if (payType === this.payTypeEnum.alipay) {
          document.write(res);
        }

      } finally {
        this.$loading.close();
      }

    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import './clubDialog.less';

@color-wx: #5fce81;
@color-ali: #06b4fd;

.pay-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 16px 0;
}

.pay-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  width: 150px;
  cursor: pointer;
  margin: 0 8px;

  img {
    height: 48px;
    width: 48px;
    margin-bottom: 8px;
  }

  &.wx.active {
    border-color: @color-wx;
    color: @color-wx;
  }


  &.alipay.active {
    border-color: @color-ali;
    color: @color-ali;
  }
}

</style>
